import { Logo } from '@146north/logotype';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material/styles';
import ReactGA from 'react-analytics-ga4';
import getMorseAlphabet from './components/morse';
import './App.css';
import { useState } from 'react';
import { ThemeProvider } from '@emotion/react';

ReactGA.initialize('G-LDDS9GWYX1');

const theme = createTheme({
	palette: {
		primary: { main: '#333333' },
	},
});

function App() {
	const [translatedText, setTranslatedText] = useState('');
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	function translateTextToMorse(input) {
		const morseAlphabet = getMorseAlphabet();

		if (input.startsWith('.') || input.startsWith('-')) {
			const morseCode = input.split(' / ');
			const words = morseCode.map((code) =>
				code
					.split(' ')
					.map((morse) => {
						for (const [key, value] of Object.entries(morseAlphabet)) {
							if (value === morse) {
								return key;
							}
						}
						return '';
					})
					.join('')
			);
			return words.join(' ');
		} else {
			const words = input.toUpperCase().split(' ');
			const morseCode = words.map((word) =>
				Array.from(word)
					.map((char) => morseAlphabet[char] || '')
					.join(' ')
			);
			return morseCode.join(' / ');
		}
	}

	function onMorseChange(event) {
		const morse = event.target.value;
		setTranslatedText(translateTextToMorse(morse));
	}

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div id="App" className="App">
				<header>
					<Logo
						text="A morsecode translator"
						logosize="80%"
						margin="10vh,5vh"
					/>
				</header>
				<main>
					<h3>Translate your morse.</h3>
					<p>
						Morsecode is fun(?), but also wierd, on this page you can translate
						morse code into readable text and back :)
					</p>
					<ThemeProvider theme={theme}>
						<TextField
							theme={theme}
							id="outlined-basic"
							label="Translate this:"
							variant="outlined"
							onChange={(e) => onMorseChange(e)}
						/>
					</ThemeProvider>
					{!translatedText && (
						<h4 className="center">Translate your morsecode above ⬆️</h4>
					)}
					{translatedText && (
						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							onClose={handleTooltipClose}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title="Copied!"
						>
							<h4
								className="center"
								onClick={() => {
									navigator.clipboard.writeText(translatedText);
									handleTooltipOpen();
								}}
							>
								🤖 {translatedText}
							</h4>
						</Tooltip>
					)}
				</main>
				<footer>&copy; 2023 Henrik Danielsson</footer>
			</div>
		</ClickAwayListener>
	);
}

export default App;
